<template>
  <div v-loading="loading">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/analysisDevice' }">设备数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>设备数据分析详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="120px">
        <div class="form-title">基本信息

          <a :href="'/statistics/wapi/v1/file/export/device/report?archivesId='+form.archivesId" target="_blank"><el-button style="margin-left: 10px;float:right" type="primary" size="mini">导出报告</el-button></a>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备名称：">{{ form.archivesName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数：">{{ form.specification }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="型号：">{{ form.model }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家：">{{ form.factory }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="form-box">
      <div class="form-title">设备巡视信息</div>
      <el-table :data="listData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="alias" label="设备别名"></el-table-column>
        <el-table-column prop="facilityName" label="位置"></el-table-column>
        <el-table-column prop="normalCount" label="正常累计"></el-table-column>
        <el-table-column prop="abnormalCount" label="异常累计"></el-table-column>
        <el-table-column prop="repairCount" label="维修累计"></el-table-column>
        <el-table-column prop="failureRate" label="异常占比"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      listData: [],
      loading: false
    };
  },
  methods: {
    loadData() {
      this.loading = true
      this.$ajax.post("archivesDetail", {
        archivesId: this.form.archivesId,
      }).then((res) => {
        this.listData = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/analysisDevice')
		} else {
      this.form = this.$route.params.row
    }
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.img-box {
  img {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-left: 10px;
  }
}
</style>
<style lang="less">
</style>
